import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CigarBrandLogoComponent } from '@shared/components/cigar-brand-logo/cigar-brand-logo.component';
import { CigarInfoComponent } from '@shared/components/cigar-info/cigar-info.component';
import { CigarReviewComponent } from '@shared/components/cigar-review/cigar-review.component';
import { UserReviewModel } from '@shared/models/user-review.model';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';
import {
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
} from '@ionic/angular/standalone';

@Component({
  selector: 'user-review',
  standalone: true,
  imports: [
    CommonModule,
    CreateSourceStandalonePipe,
    UserNamePipe,
    CigarReviewComponent,
    CigarBrandLogoComponent,
    CigarInfoComponent,
    IonCard,
    IonCardContent,
    IonItem,
    IonLabel,
  ],
  template: `
    <ion-card>
      <ion-card-content>
        <ion-item lines="none" *ngIf="showDate">
          <ion-label>
            <p>{{ review.CreatedOn | date : 'MMM d, y, HH:mm' }}</p>
          </ion-label>
        </ion-item>
        <div class="py-2 mb-2 rounded-lg bg-neutral-50 dark:bg-neutral-800">
          <cigar-info
            [cigar]="review"
            [name]="review.CigarName"
            [prices]="review.CigarPrices"
            [minBoxQty]="review.MinBoxQty"
            [maxBoxQty]="review.MaxBoxQty"
          >
          </cigar-info>
        </div>
        <cigar-review [review]="review"></cigar-review>
      </ion-card-content>
    </ion-card>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class UserReview implements OnInit {
  @Input() review: UserReviewModel;
  @Input() showDate = true;
  constructor() {}

  ngOnInit() {}
}
