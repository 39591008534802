import { Pipe, PipeTransform } from '@angular/core';
import { UserReviewModel } from '@shared/models/user-review.model';
import { format } from 'date-fns';

@Pipe({
  name: 'reviewListGroupByDate',
  standalone: true,
})
export class ReviewListGroupByDatePipe implements PipeTransform {
  transform(list: UserReviewModel[]): {
    date: string;
    list: UserReviewModel[];
  }[] {
    const byDates: Record<string, UserReviewModel[]> = {};

    list.forEach((review) => {
      const date = format(new Date(review.CreatedOn), 'MMMM y');
      if (!byDates[date]) {
        byDates[date] = [];
      }
      byDates[date].push(review);
    });

    return Object.keys(byDates).map((date) => ({
      date,
      list: byDates[date],
    }));
  }
}
